import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0,4];

export const dictionary = {
		"/(unprotected)/(marketing)": [17,[3]],
		"/admin": [~24],
		"/admin/review/[jobId]/[talentId]": [~25],
		"/admin/users": [~26],
		"/(client)/c/agreements": [~5,[2]],
		"/(client)/c/agreements/new": [~7,[2]],
		"/(client)/c/agreements/[agreementPublicId]/[version]": [~6,[2]],
		"/(client)/c/jobs": [~8,[2]],
		"/(client)/c/jobs/new": [~12,[2]],
		"/(client)/c/jobs/[publicId]": [~9,[2]],
		"/(client)/c/jobs/[publicId]/edit": [~10,[2]],
		"/(client)/c/jobs/[publicId]/profiles/[talentPublicId]": [~11,[2]],
		"/(client)/c/profile": [~13,[2]],
		"/(unprotected)/forgot-password": [~20],
		"/forward": [~27],
		"/inbox": [28,[4]],
		"/inbox/[publicId]": [~29,[4]],
		"/(unprotected)/join": [~21],
		"/(unprotected)/login": [~22],
		"/logout": [~30],
		"/(unprotected)/(marketing)/privacy-policy": [18,[3]],
		"/(unprotected)/reset-password": [~23],
		"/(unprotected)/(marketing)/terms-conditions": [19,[3]],
		"/(talent)/t/jobs": [~14],
		"/(talent)/t/jobs/[publicId]": [~15],
		"/(talent)/t/profile": [~16]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';